<script>
import CoinsSvg from "@/pages/balance/CoinsSvg.vue";

export default {
  name: "BalanceMirrorUsdt",
  components: {CoinsSvg},
  props: {
    token: {
      type: Object,
      required: true
    },
    chartDataMounted: {
      type: Object,
      required: true
    },
    coinsList: {
      type: Array,
      default: null
    },
  },
  computed: {
    nodeBalanceAvailable() {
      return this.chartDataMounted.node[this.token].balanceUsdt;
    },
    exchangeBalanceAvailable() {
      return this.chartDataMounted.exchange[this.token.name].balanceUsdt;
    },
    totalBalanceAvailable() {
      return this.totalNetworkBalance + this.exchangeBalanceAvailable;
    },
    nodePercentage() {
      return Math.round((this.totalNetworkBalance / this.totalBalanceAvailable) * 100);
    },
    exchangePercentage() {
      return 100 - this.nodePercentage;
    },
    totalNetworkBalance() {
      let totalBalance = 0;
      for (const network of this.token.networks) {
        const networkName = network.name;
        totalBalance += this.chartDataMounted.node[networkName].balanceUsdt ;
      }

      return totalBalance ;
    }
  },
  methods: {
    availablePercentage(available, blocked) {
      const total = available + blocked

      return (available / total) * 100;
    },
    lockedPercentage(available, blocked) {
      const total = available + blocked

      return (blocked / total) * 100
    },
    filteredData(token, category, fieldKey) {
      const categoryData = this.chartDataMounted[category];

      if (categoryData && categoryData[token] && categoryData[token]) {
        const tokenData = categoryData[token];
        return tokenData.hasOwnProperty(fieldKey) ? tokenData[fieldKey] : "";
      }

      return "";
    },
    calculateNetworkBalances() {
      const totalBalance = this.totalNetworkBalance + this.chartDataMounted.exchange[this.token.name].balanceUsdt;
      let percentages = {};

      for (const network of this.token.networks) {
        let networkTRXBalance;
        let networkETHBalance;

        networkTRXBalance = this.chartDataMounted.node.USDT_TRX.balanceUsdt;
        networkETHBalance = this.chartDataMounted.node.USDT_ETH.balanceUsdt;

        const percentageETH = (networkETHBalance / totalBalance) * 100;
        const percentageTRX = (networkTRXBalance / totalBalance) * 100;

        percentages = {
          eth: percentageETH.toFixed(0),
          trx: percentageTRX.toFixed(0)
        };
      }

      return percentages
    },
    nodePercentageHandler(item) {
      const percentages = this.calculateNetworkBalances();
      const firstPercentage = percentages.eth;
      const secondPercentage = percentages.trx;

      return `
    <span class="${item.name === 'USDT_ETH' ? 'large-font' : 'small-font'}">${firstPercentage}%</span>
    <span class="${item.name === 'USDT_TRX' ? 'large-font' : 'small-font'}">${secondPercentage}%</span>
  `;
    },
    percentageHandler(item) {
      let token = item.network.toLowerCase();
      const percentages = this.calculateNetworkBalances();


      return percentages[token]
    },
    amountToFixedUsdt(val) {
      if (val !== undefined && val !== null && !isNaN(val)) {
        val = parseFloat(val);
        const fixedVal = val.toFixed(2).replace(/\.?0+$/, '');

        if (val >= 10000) {
          return parseFloat(fixedVal).toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }).replace(/\.00$/, '');
        } else {
          return fixedVal;
        }
      }
    },
    amountToFixedToken(val, token) {
      let numberToFixed = 4;

      if (token.toUpperCase() === 'BTC' || token.toUpperCase() === 'ETH') {
        numberToFixed = 4;
      } else if (['LTC', 'XMR', 'TRX', 'USDT', 'USDT_ETH', 'USDT_TRX', 'USDC', 'USDC_ETH', 'USDC_TRX'].includes(token.toUpperCase())) {
        numberToFixed = 2;
      }

      if (val > 0) {
        let fixedVal = val.toFixed(numberToFixed);

        let parts = fixedVal.split('.');
        if (parseFloat(parts[0].replace(/,/g, '')) >= 10000) {
          parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
        return parts.join('.').replace(/\.?0+$/, '');
      } else {
        return '0';
      }
    },
    amountToFixed(val, token) {
      let numberToFixed = 0;

      if (token.toUpperCase() === 'BTC' || token.toUpperCase() === 'ETH') {
        numberToFixed = 4;
      } else if (token.toUpperCase() === 'LTC' || token.toUpperCase() === 'XMR' || token.toUpperCase() === 'TRX' || token.toUpperCase() === 'USDT' || token.toUpperCase() === 'USDT_ETH' || token.toUpperCase() === 'USDT_TRX' || token.toUpperCase() === 'USDС' || token.toUpperCase() === 'USDС_ETH' || token.toUpperCase() === 'USDС_TRX') {
        numberToFixed = 2;
      } else {
        numberToFixed = 4;
      }

      if (val !== undefined && val !== null) {
        const fixedVal = parseFloat(val).toFixed(numberToFixed);

        const formattedVal = fixedVal.replace(/(\.[0-9]*[1-9])0+$/, '$1');

        if (val >= 10000) {
          return parseFloat(formattedVal).toLocaleString('en-US', {
            minimumFractionDigits: 1,
            maximumFractionDigits: 5
          });
        } else {
          return formattedVal;
        }
      }
    },


  }
}
</script>

<template>
  <div class="table-block_table--row_usdt">
    <div class="table-block_table--row table-block_table--row__head">
      <div class="table-block_table--row_block table-block_table--row_block-left">
        <div
          class="table-block_table--row--item table-block_table--row--item_coin table-block_table--row--item_coin-left"
          :class="{
            'table-block_table--row--item_coin-red': nodePercentage >= 0 && nodePercentage <= 20,
            'table-block_table--row--item_coin-green': nodePercentage >= 21 && nodePercentage <= 80,
            'table-block_table--row--item_coin-gray': nodePercentage >= 81 && nodePercentage <= 100

          }"
        >
          <div class="table-block_table--row--item_coin-block">
            <CoinsSvg
              :coin="token.name"
              :coins-list="coinsList"
            />
            <div class="info">
              <div class="name">
                {{ token.name }}
              </div>
              <div class="full-name">
                Node
              </div>
            </div>
          </div>
          <div class="table-block_table--row--item_coin-block_percentage">
            {{ nodePercentage }}%
          </div>
        </div>
      </div>

      <div class="table-block_table--row_block table-block_table--row_block-right">
        <div
          class="table-block_table--row--item table-block_table--row--item_coin table-block_table--row--item_coin-right"
          :class="{
            'table-block_table--row--item_coin-red': exchangePercentage >= 0 && exchangePercentage <= 20,
            'table-block_table--row--item_coin-green': exchangePercentage >= 21 && exchangePercentage <= 80,
            'table-block_table--row--item_coin-gray': exchangePercentage >= 81 && exchangePercentage <= 100

          }"
        >
          <div class="table-block_table--row--item_coin-block">
            <CoinsSvg
              :coin="token.name"
              :coins-list="coinsList"
            />
            <div class="info">
              <div class="name">
                {{ token.name }}
              </div>
              <div class="full-name">
                Mrkt
              </div>
            </div>
          </div>
          <div class="table-block_table--row--item_coin-block_percentage">
            {{ exchangePercentage }}%
          </div>
        </div>
      </div>
    </div>
    <div
      v-for="(item, index) in token.networks"
      :key="index"
      class="table-block_table--row table-block_table--row__networks"
    >
      <div class="table-block_table--row_block table-block_table--row_block-left">
        <div
          v-if="token.name !== 'USDC'"
          class="table-block_table--row--item table-block_table--row--item_coin table-block_table--row--item_coin-left"
          :class="{
            'table-block_table--row--item_coin-red': percentageHandler(item) >= 0 && percentageHandler(item) <= 20,
            'table-block_table--row--item_coin-green': percentageHandler(item) >= 21 && percentageHandler(item) <= 80,
            'table-block_table--row--item_coin-gray': percentageHandler(item) >= 81 && percentageHandler(item) <= 100

          }"
        >
          <div class="table-block_table--row--item_coin-block">
            <CoinsSvg
              :coin="token.name"
              :coins-list="coinsList"
            />
            <div class="info">
              <div class="name">
                {{ token.name }}
              </div>
              <div
                class="network"
                :class="{
                  'network_trx': item.network === 'TRX',
                  'network_eth': item.network === 'ETH',
                }"
              >
                {{ item.network }}
              </div>
            </div>
          </div>
          <div
            class="table-block_table--row--item_coin-block_percentage"
            v-html="nodePercentageHandler(item)"
          />
        </div>

        <div class="table-block_table--row--item bars bars-cell">
          <div class="bars-cell--bar bars-cell--row ">
            <div class="container">
              <div
                class="part1"
                :style="{
                  width: `${availablePercentage(
                    filteredData(item.name, 'node', 'balanceAvailable'),
                    filteredData(item.name, 'node', 'balanceLocked')
                  )}%`,
                  backgroundColor: '#00B18B' }"
              />
              <div
                class="part2"
                :style="{
                  width: `${lockedPercentage(
                    filteredData(item.name, 'node', 'balanceAvailable'),
                    filteredData(item.name, 'node', 'balanceLocked')
                  )}%`,
                  backgroundColor: '#FF603D' }"
              />
              <div
                class="part3"
                :style="{
                  width: `0%`,
                  backgroundColor: 'gray' }"
              />
            </div>
          </div>

          <div class="bars-cell--value bars-cell--row ">
            <div class="bars-cell--item">
              <span>Available</span>
              {{ amountToFixedToken(filteredData(item.name, 'node', 'balanceAvailable'), item.name) }}
              {{ token.name }}
            </div>

            <div class="bars-cell--item">
              <span>Blocked</span>
              {{ amountToFixedToken(filteredData(item.name, 'node', 'balanceLocked'), item.name) }} {{ token.name }}
            </div>
            <div class="bars-cell--item bars-cell--item_total">
              <span>Total node</span>
              <div>
                {{
                  amountToFixed(filteredData(item.name, 'node', 'balanceAvailable') + filteredData(item.name, 'node', 'balanceLocked'), item.name)
                }}
                {{ token.name }}
              </div>
              <div>
                {{
                  amountToFixedUsdt(filteredData(item.name, 'node', 'balanceUsdt'), item.name)
                }} $
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="table-block_table--row_block table-block_table--row_block-right">
        <div
          v-if="token.name !== 'USDC'"
          class="table-block_table--row--item table-block_table--row--item_coin table-block_table--row--item_coin-right"
          :class="{
            'table-block_table--row--item_coin-red': exchangePercentage >= 0 && exchangePercentage <= 20,
            'table-block_table--row--item_coin-green': exchangePercentage >= 21 && exchangePercentage <= 80,
            'table-block_table--row--item_coin-gray': exchangePercentage >= 81 && exchangePercentage <= 100

          }"
        >
          <div class="table-block_table--row--item_coin-block">
            <CoinsSvg
              :coin="token.name"
              :coins-list="coinsList"
            />
            <div class="info">
              <div class="name">
                {{ token.name }}
              </div>
              <div class="full-name">
                Mrkt
              </div>
            </div>
          </div>

          <div class="table-block_table--row--item_coin-block_percentage">
            {{ exchangePercentage }}%
          </div>
        </div>

        <div class="table-block_table--row--item bars bars-cell">
          <div class="bars-cell--bar bars-cell--row ">
            <div class="container">
              <div
                class="part1"
                :style="{
                  width: `${availablePercentage(
                    filteredData(token.name, 'exchange', 'balanceAvailable'),
                    filteredData(token.name, 'exchange', 'balanceLocked')
                  )}%`,
                  backgroundColor: '#00B18B' }"
              />
              <div
                class="part2"
                :style="{
                  width: `${lockedPercentage(
                    filteredData(token.name, 'exchange', 'balanceAvailable'),
                    filteredData(token.name, 'exchange', 'balanceLocked')
                  )}%`,
                  backgroundColor: '#FF603D' }"
              />
              <div
                class="part3"
                :style="{
                  width: `0%`,
                  backgroundColor: 'gray' }"
              />
            </div>
          </div>
          <div class="bars-cell--value bars-cell--row ">
            <div class="bars-cell--item">
              <span>Available</span>
              {{ amountToFixedToken(filteredData(token.name, 'exchange', 'balanceAvailable'), token.name) }}
              USDT
            </div>

            <div class="bars-cell--item">
              <span>Blocked</span>
              {{ amountToFixedToken(filteredData(token.name, 'exchange', 'balanceLocked'), token.name) }}
              {{ token.name }}
            </div>
            <div class="bars-cell--item">
              <span>Total node</span>
              {{
                amountToFixed(filteredData(token.name, 'node', 'balanceAvailable') + filteredData(item.name, 'node', 'balanceLocked'), token.name)
              }}
              {{ token.name }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.network {
  color: #FFF;
  text-align: center;
  font-family: 'Inter', sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px;
  border-radius: 3px;
  display: grid;
  place-items: center;
  width: 26px;
  height: 14px;

  &_trx {
    background: #0A68F7;
  }

  &_eth {
    background: #FB0000;
  }
}
</style>
